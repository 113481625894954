<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>有为才有位——有为信通助事业单位各类会议、活动顺利进行！</p>
          <p>发表时间:2021-02-05 14:38</p>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyouletter/Thankyouletter.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe" style="margin-bottom: 40px;">
          <div class="bg">
            <span>
              有为信通专注于客户数据业务创新，依托云计算、大数据技术为众多政府、教育、电力、能源、企业等行业提供创新性的产品和解决方案。有为信通的业务方向涉及智慧城市、智慧电网、IDC智能化云运维，产品涵盖智能化音视频信息通信，大数据业务可视化、信息数据交换共享，IDC系统集成及智能化云运维服务、智慧园区创新应用管理平台等领域。
              2020年受新冠疫情影响，文化和旅游部综合监测与应急指挥平台承担各类重大会议、活动230余次。在此期间，有为信通为文化和旅游部433应急指挥中心提供了专业的技术保障服务。技术人员响应速度及时、工作态度认真、技术能力过硬，展现了较好的敬业精神和优质的服务能力。
              文化和旅游部对我司及我司一线技术服务同志致以诚挚的感谢！
              我们将持续努力，提升自我，为广大用户提供更加优质、专业的视频会议、智能安防相关服务！
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #9c9b9b;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #fef5f5;
        color: #fe7567;
        font-size: 18px;
        padding: 40px 48px 0 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>